.user-account {
    height: 33rem;
    /* height: auto; */
    box-shadow: var(--shadow);
    border-radius: 10px;
    /* border: var(--border-dashed); */
}

.user-dash {
    margin: 0 0 5rem 0 !important;
}

.user-dash .container {
    /* max-width: 1500px !important; */
    max-width: 1700px !important;
}


.user-dash-title {
    color: var(--clr-blue);
    font-size: 14px;
    letter-spacing: .9px;
    opacity: .9;
    transition: .3s !important;
}

.user-dash-title:hover {
    color: var(--clr-blue) !important;
}

.user-dash-title .user-dash-row::after {
    content: "";
    width: 0rem;
    background-color: var(--clr-blue);
    height: .13rem;
    margin: 0 0 0 2.5rem;
    position: absolute;
    transition: var(--transition) !important;
    border-radius: 10px !important;
}

.user-dash-title .user-dash-row:hover::after {
    content: "";
    width: 10rem;
    background-color: var(--clr-blue);
    height: .13rem;
    margin: 0 0 0 2.5rem;
    position: absolute;
}



.user-dash-title span {
    text-transform: uppercase;
}


.dash-icons {
    font-size: 23px !important;
    color: var(--clr-blue) !important;
}

.urls-link {
    color: var(--clr-blue) !important;
    transition: var(--transition) !important;

}

.urls-link:hover {
    margin: 0 0 0 1rem;
}

.mon-compte-title {
    font-size: 4rem !important;
}

.margin-left {
    margin: 0 0 0 1.7rem !important;
}

@media screen and (max-width:768px) {
    .margin-left {
        margin: 0 0 0 0rem !important;
    }
}