.shop-header-container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.container-image {
    position: relative !important;
    bottom: 0 !important;
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    margin-top: 44rem !important;
}

.container-image-parent {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items:flex-end;
}