.link-btn {
    font-size: 14px !important;
    box-shadow: var(--shadow-yellow);
    background-color: var(--clr-yellow) !important;
    border: var(--border-yellow) !important;
    color: var(--clr-light) !important;
    text-transform: uppercase !important;
    letter-spacing: .5px !important;
    transition: var(--transition) !important;
}

.link-btn:hover {
    background-color: #df9400 !important;
}