/* Nav bar */

.header-nav {
  /* background-color: var(--light) !important; */
  background-color: var(--clr-light) !important;
  height: 80px !important;
  box-shadow: rgb(0 17 51 / 4%) 0px 13px 11px;
  position: relative !important;
  z-index: 99 !important;
  /* opacity: .9 !important; */
}

@media screen and (max-width: 1400px) {

  /* @media screen and (max-width: 991px) { */
  .header-nav {
    height: auto !important;
  }

  .navbar-collapse {
    margin: 2rem 0 0 0 !important;
  }

  .user-header {
    position: absolute !important;
  }

  /* .navbar-nav li.dropdown-menu:hover>ul.dropdown-menu {
        display: block !important;
        position: absolute !important;
      } */
}

.navbar-toggler {
  border: var(--border-dashed) !important;
  border-radius: 50% !important;
  height: 46px !important;
  width: 46px !important;
  box-shadow: var(--shadow-1);
  outline: none !important;

}

.navbar-collapse {
  transition: var(--transition) !important;
}


.navbar-toggler:focus {
  box-shadow: var(--shadow-1) !important;
}

.navbar-toggler-icon {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-18%, -2%) !important;
}

.user-header {
  margin: 1.7rem 0 0 0 !important;
}

.leonidas-logo {
  height: 60px;
  transition: var(--transition);
  transform: scale(1.4);
}

.leonidas-logo:hover {
  opacity: 0.8;
}

.navbar-nav a {
  /* font-family: var(--font-family-lato-reg) !important; */
  /* color: var(--clr-dark); */
  /* text-transform: uppercase; */
  letter-spacing: .5px !important;
  /* font-size: 16px !important; */
  /* margin: 3px 0px 6px 0px !important; */
}

.navbar-icon {
  /* color: var(--yellow) !important; */
  color: var(--clr-dark) !important;
  font-size: 1.7rem !important;
  cursor: pointer;
  z-index: 9999 !important;
  position: relative;
}

.navbar-icon:hover {
  color: var(--yellow) !important;
}

.icon-items {
  margin: 0 -3px 0 0px !important;
  z-index: 1;
  position: sticky;
}

.icon-items .row:nth-child(2) {
  margin: 0 -4rem !important;
}

@media screen and (min-width:992px) {
  .icon-items {
    margin: 0 -28px 0 0px !important;
  }

  /* .col-search{
    margin: 0 0 2rem 0 !important;
  } */

}

.user-account-icon {
  padding-right: 1.5rem !important;
  margin: 0 .6rem !important;
}

.nav-item-image-container {
  width: 200px !important;
  height: 120px !important;
  border-radius: var(--radius);
  box-shadow: var(--shadow-1);
  overflow: hidden;
  padding: 0 !important;
}


@media screen and (max-width:578px) {
  .icon-items .row:nth-child(2) {
    margin: 0 0rem !important;
  }

  .user-account-icon {
    padding: 0 !important;
  }

  .nav-item-image-container {
    width: 97% !important;
    height: 110px !important;
  }


}

/* @media screen and (max-width:992px) {
  .navbar-brand {
    margin: 0 0 0 -10rem !important;
  }

  .navbar-toggler {
    margin: 0px 0px 0 6rem !important;
  }
} */


.badge-card {
  z-index: 9999 !important;
}

.active-link {
  background-color: var(--yellow) !important;
  border-radius: var(--radius) !important;
  padding: 6px 10px !important;
  color: var(--light) !important;
}

.active-link a {
  color: var(--dark) !important;
}

.navbar-link {
  transition: all .7s;
  border-radius: var(--radius) !important;
  padding: 6px 10px !important;
}

.navbar-link:hover {
  background-color: var(--light-yellow) !important;
  color: var(--dark) !important;
}

.bar-icon::after {
  content: "" !important;
  position: absolute !important;
  height: 20px !important;
  width: 2px !important;
  color: var(--dark) !important;
  background-color: var(--dark) !important;
  margin: 8px 0 0 3px !important;
}



/* Dropdown Menu */

.navbar-nav .dropdown-menu.show {
  position: absolute !important;
}

@media screen and (max-width:768px) {
  /* .navbar-nav .dropdown-menu.show {
    display: contents !important;
  } */

  .dropdown-item {
    padding: 0px 10px !important;
  }

  .navbar-nav .sub-dropdown-item {
    margin: -10px 0px 0px 0px !important;
    color: var(--blue) !important;
  }
}

@media screen and (max-width:578px) {
  .navbar-nav .dropdown-menu.show {
    max-width: 20rem !important;
    max-height: 36rem;
  }


}

/* ====================== */
.dropdown-menu {
  transition: var(--transition) !important;
}

.navbar-nav li.dropdown-submenu:hover>ul.dropdown-menu {
  display: block !important;
  position: absolute !important;
}

/* .navbar-nav li.dropdown-submenu:hover>ul.dropdown-menu {
  display: block !important;
  position: absolute !important;
} */



.dropdown-menu {
  border: none !important;
  border-top: 2px var(--yellow) solid !important;
  background-color: var(--light) !important;
  box-shadow: var(--shadow-2) !important;
  margin: .0rem 0 0 0 !important;
}

.sub-dropdown-menu {
  margin: -2rem 0 0 15rem !important;
  position: absolute !important;
}

.dropdown-menu-categorie {
  width: 30rem !important;
  max-height: 47rem;
  background-color: var(--clr-light) !important;
  overflow-y: scroll;
  padding: 1rem !important;
  z-index: 9999 !important;
}

.dropdown-menu li {
  display: block !important;
  padding: 11px 20px 0px 20px !important;
  margin: 2px 0 -7px -8px !important;
}

.nav-item-title {
  width: 205px !important;
}

.nav-item-title p {
  font-size: 14px;
  padding: .3rem 0 1rem 0;
}

/* .dropdown-menu li:not(:last-child)::after {
  content: "" !important;
  position: absolute !important;
  height: 1px !important;
  width: 90% !important;
  color: var(--dark) !important;
  background-color: var(--light-dark) !important;
  padding: 0px 0px 0 0;
} */

.dropdown-item {
  display: block !important;
  padding: 5px 10px !important;
  clear: both !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #212529 !important;
  text-align: inherit !important;
  white-space: nowrap !important;
  background-color: transparent !important;
  border: 0 !important;
  transition: all .5s !important;
}


.dropdown-item:hover {
  background-color: var(--light-yellow) !important;
  /* color: var(--light) !important; */
  border-radius: var(--radius) !important;
  border-bottom: 1px var(--dark) solid !important;
}

.greater {
  right: 10px !important;
  position: absolute !important;
  opacity: .6 !important;
  transform: scale(.7) !important;
}




.nav-item-image {
  width: 100%;
  height: 100%;
  border-radius: var(--radius);
  box-shadow: var(--shadow-1);
  object-fit: cover !important;
  transition: var(--transition);
}

.nav-item-marque-image {
  width: 100%;
  height: 100%;
  border-radius: var(--radius);
  box-shadow: var(--shadow-1);
  object-fit: fill !important;
  transition: var(--transition);
}

.nav-item-image:hover,
.nav-item-marque-image:hover {
  transform: scale(1.2);
}


/* search-box */

.search-box {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 98%;
  background: var(--light);
  border-radius: 10px;
  box-shadow: var(--shadow-2);
  padding: 1rem;
  font-family: var(--font-family-lato-bold);
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.7s ease, height 0.7s ease, margin 0.7s ease, all 0.7s ease;
  margin: -2rem 0 2rem 0rem !important;
}

.search-box.show {
  opacity: 1;
  height: auto;
  max-height: 80vh;
  overflow: scroll;
  padding: 1rem;
  margin: 2rem 0 7rem 0rem !important;
}

.input-group-append {
  margin-top: -2.3rem;
}

.search-box-title {
  color: var(--gray-2);
  opacity: 0.8;
}

.search-box-icon {
  font-size: 25px;
  color: var(--clr-gold);
  cursor: pointer;
  transition: var(--transition);
}

.search-box-icon:hover {
  color: var(--dark);
}

.search-box-input {
  width: 100%;
  border: 1px solid var(--light-dark);
  border-radius: var(--radius);
  margin-top: 1.7rem;
  outline: none !important;
  font-size: 20px;
  transition: var(--transition);
  height: 2.9rem;
}

.display-all {
  border-radius: var(--radius) !important;
  /* width: 20rem !important; */
  width: auto !important;
  padding: .8rem 1.1rem !important;
  border: 0.15rem solid var(--clr-gold) !important;
  text-transform: uppercase !important;
  margin: 0 0px 0px 0 !important;
  color: var(--clr-gold) !important;
  background-color: var(--clr-light-gold) !important;
  transition: var(--transition) !important;
}

.display-all:hover {
  color: var(--light) !important;
  background-color: var(--clr-gold) !important;
}


@media screen and (min-width: 1400px) and (max-width: 1510px) {
  .search-box-input {
    width: 90%;
    margin-left: 1rem;
  }
}

@media screen and (max-width: 1400px) {
  .search-box-input {
    width: 100%;
  }
}

.search-box-input:focus {
  border: 1px solid var(--light-dark) !important;
  box-shadow: none !important;
}

.search-box-input::placeholder {
  font-size: 19px;
  color: var(--gray);
}

.fs-6 {
  font-size: 1.3rem !important;
}



/* @media screen and (max-width: 1400px) {
  .dropdown-menu-categorie {
    width: 57rem !important;
  }
} */

@media screen and (max-width: 1400px) and (min-width:992px) {

  .search-col {
    top: 0px !important;
    position: absolute !important;
    left: 18% !important;
  }
}

/* @media screen and (max-width: 1200px) {
  .dropdown-menu-categorie {
    width: 44rem !important;
  }
} */

@media screen and (max-width: 992px) {
  /* .dropdown-menu-categorie {
    width: 33rem !important;
  } */

  .search-col {
    margin-bottom: 1rem !important;
  }
}

@media screen and (max-width: 1400px) {
  .navbar-toggler.collapsed {
    margin: -24px 0 0 0 !important;
    right: -20px !important;
    position: absolute !important;
    z-index: 999 !important;
  }

  .navbar-toggler {
    margin: -24px 0 0 0 !important;
    right: -20px !important;
    position: absolute;
    z-index: 999 !important;
  }

  .user-header {
    margin: 2rem 0 0 0 !important;
    right: 8% !important;
  }

  .navbar-brand {
    margin: 0rem 0 0 -1rem !important;
  }

  .dropdown-menu li {
    padding: 11px 0px 0px 20px !important;
  }

}

@media screen and (max-width: 692px) {
  .user-header {
    margin: 2rem 0 0 0 !important;
    right: 10% !important;
  }

  .search-box-input {
    margin-top: .6rem;
  }
}

@media screen and (max-width: 568px) {
  .user-header {
    margin: 2rem 0 0 0 !important;
    right: 13% !important;

  }
}

@media screen and (max-width: 568px) {
  .user-header {
    margin: 2rem 0 0 0 !important;
    right: 13% !important;

  }
}

@media screen and (max-width: 450px) {
  .user-header {
    margin: 2rem 0 0 0 !important;
    right: 23% !important;

  }
}