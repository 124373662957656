.submit-btn {
    font-size: 14px !important;
    box-shadow: var(--shadow-yellow);
    background-color: var(--clr-yellow) !important;
    border: var(--border-yellow) !important;
    color: var(--clr-light) !important;
    text-transform: uppercase !important;
    letter-spacing: .5px !important;
    transition: var(--transition) !important;
}

.submit-btn:hover {
    background-color: #df9400 !important;
}

.addtocart-icon {
    font-size: 20px !important;
    margin: 0px 9px 1px -7px !important;
    transform: scale(1.3) !important;
}


/* ------------------------- */

.submit-btn-1 {
    font-size: 14px !important;
    box-shadow: var(--shadow-orange);
    background-color: var(--clr-orange) !important;
    border: 2px solid var(--clr-orange) !important;
    color: var(--clr-light) !important;
    text-transform: uppercase !important;
    letter-spacing: .5px !important;
    transition: var(--transition) !important;
}

.submit-btn-1:hover {
    background-color: var(--clr-light) !important;
    color: var(--clr-orange) !important;
}