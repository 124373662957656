.shop-about-us {
    margin: 3rem 0rem !important;
    min-height: 14rem;
    background-color: var(--clr-cream) !important;
}

.tag-hr {
    background-color: var(--clr-yellow);
    height: 3px;
    opacity: 1;
    width: 100%;
}

.tag-hr::before,
.tag-hr::after {
    display: none !important;
}