.filter {
    height: auto;
    box-shadow: var(--shadow);
    border-radius: 10px;
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

@media screen and (min-width:768px) {
    .filter {
        min-height: 42rem !important;
    }
}

@media screen and (max-width:768px) {
    .shopping-card-empty-image {
        width: 20% !important;
    }

    .filter {
        margin-top: -2rem !important;
    }
}


/* .boutique {
    transform: scale(.98);
    margin: 0 0 20rem 0;
} */

/* .boutique .container {
    max-width: 1700px !important;
} */


@media screen and (max-width:1526px) and (min-width:1400px) {
    .boutique .product-info {
        margin: -2.6rem 0 0 0;
    }

    .boutique .view {
        margin: -8rem 0 1rem 0;
    }

    /* .boutique .product-price {
        margin: 0 0 4rem 0;
    } */

    .boutique .submit-btn {
        font-size: 12px !important;
        padding: 14px 2rem !important;
        margin: -2rem 0 0.3rem 0 !important;
        transform: scale(1.1);
    }
}

@media screen and (max-width:880px) and (min-width:768px) {
    /* .boutique .product-info {
        margin: -2.6rem 0 0 0;
    }

    .boutique .view {
        margin: -8rem 0 1rem 0;
    }

    .boutique .product-price {
        margin: 0 0 4rem 0;
    } */

    .boutique .submit-btn {
        font-size: 12px !important;
        padding: 14px 2rem !important;
        margin: -2rem 0 0.3rem 0 !important;
        transform: scale(1.1);
    }
}

@media screen and (max-width:590px) and (min-width:100px) {
    .boutique.isgrid .product-info {
        /* margin: -4rem 0 0 0; */
        margin: -1.4rem 0 0 0;
        height: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .boutique.isgrid a .product-title {
        font-size: 17px !important;
        line-height: 1.3rem !important;
    }

    .boutique.isrow .product-info {
        /* margin: -1rem 0 0 0 !important; */

        /* margin: -1.5rem 0 0 0 !important; */
        height: 5rem;
        /* display: flex;
        justify-content: center;
        align-items: center; */
    }

    /* .boutique.isrow .view {
        margin: -2.5rem 0 2rem 0;
        transform: scale(1.2);
    } */

    /* .boutique.isgrid .product-price {
        margin: 0 0 3.5rem 0;
    } */

    .boutique.isgrid .view {
        /* margin: -11rem 0 2rem 0; */
        /* margin: -9.5rem 0 2rem 0; */
        margin: -5rem 0 4.2rem 0;
        transform: scale(1.2);
    }

    .boutique.isgrid .submit-btn {
        font-size: 60% !important;
        /* font-size: 10px !important; */
        padding: 14px 12% !important;
        /* padding: 14px 1.4rem !important; */
        margin: -2rem 0 0.3rem 0 !important;
        transform: scale(1.1);
    }

    .product-item.isrow {
        max-height: 28.7rem !important;
        min-height: 28.7rem !important;
    }

    .product-item.isgrid {
        max-height: 28.7rem !important;
    }

    /* .product-item.isGrid {
        max-height: 28rem !important;
    } */

    .product-item {
        max-width: 360px !important;
    }

    /* .product-item.isrow .product-img {
        width: 70% !important;
    } */

    .add-to-card-container.isgrid {
        min-height: 6.2rem;
    }

    .variant-row-m2.isgrid{
        margin: 0px 0px 6.3rem 0 !important;
    }

}


.filter-title {
    color: var(--clr-dark);
    font-size: 14px;
    letter-spacing: .9px;
}


.accordion-item {
    border: none !important;
    width: 100% !important;
    box-shadow: none !important;
    background: none !important;
}

.accordion-button {
    font-size: 13px !important;
    letter-spacing: 1px !important;
    opacity: .8 !important;
    padding: 12px 0 0px 20px !important;
    transition: var(--transition) !important;
    color: var(--clr-dark) !important;
}

.accordion-button:hover {
    color: var(--clr-blue) !important;
}

.accordion-header,
.accordion-button {
    box-shadow: none !important;
    background: none !important;
    transition: var(--transition) !important;
    text-transform: uppercase !important;
}

.accordion {
    box-shadow: none !important;
    background: none !important;
    background-color: transparent !important;
}

.accordion-button::after {
    opacity: .7 !important;
    transform: scale(.5) !important;
}

.accordion-body {
    color: var(--clr-blue) !important;
    font-size: 14px !important;
    padding: 10px 0 0 40px !important;
    letter-spacing: 1px;
}

.accordion-body li {
    padding: 0 0 5px 0 !important;
    text-transform: uppercase !important;
    transition: var(--transition) !important;
}

.accordion-body li:hover {
    padding: 0 0 11px 10px !important;
    /* color: var(--clr-blue) !important; */
}

.accordion-button:hover {
    box-shadow: none !important;
    background: none !important;
    /* transform: scale(1.025) !important; */
    /* color: var(--clr-light) !important;
    border-radius: 5px !important; */
}

.filter-price-btn {
    font-family: var(--font-family-5) !important;
    transition: var(--transition) !important;
    font-weight: 600 !important;
    letter-spacing: 1px;
    outline: none !important;
    border: none !important;
}

.filter-price-btn:hover {
    box-shadow: none !important;
    background: none !important;
    color: var(--clr-blue) !important;
    font-family: var(--font-family-lato-reg) !important;
}

.filter-price-value {
    font-size: 12px !important;
    font-family: var(--font-family-5) !important;
    font-weight: 600 !important;
    opacity: .8 !important;
}

.filter-tri span,
.filter-result {
    text-transform: uppercase;
}

.hidden {
    display: none !important;
}

.accordion-body-marque {
    padding: 0px 0 0 40px !important;
}

.accordion-body-marque li {
    padding: 0 0 0px 0 !important;
}

.accordion-body-marque li:hover {
    padding: 0 0 0 10px !important;
}

.accordion-body-marque:first-child {
    /* padding: 10px 0 0 40px !important; */
    margin: 10px 0 0 0 !important;
}

.select-option,
.select-option option {
    font-family: var(--font-family-lato-bold) !important;
}

.select-option:focus {
    box-shadow: none !important;
}

.select-option option:first-child {
    margin: 10px 0 0 0 !important;
    padding: 10px 0 0 0 !important;
}


.gthan {
    color: var(--clr-dark);
    opacity: .6;
    transform: rotate(80deg);
}

.loading-data {
    box-shadow: var(--shadow-2);
    border-radius: var(--radius-2);
    padding: 0 0px 0 18px;
    height: 4rem;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    right: 0;
    position: relative;
    transform: translate(1%, 14%);
    overflow: hidden;
}

.categorie-filter {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-size-width);
    height: var(--bs-accordion-btn-size-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-size-width);
    transition: var(--bs-accordion-btn-size-transition);
}