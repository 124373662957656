.bg-footer {
    background-color: var(--clr-blue) !important;
    box-shadow: var(--shadow-yellow);
}

.copy-right-bg {
    background-color: var(--clr-blue) !important;
}

.bg-footer .input-group-append {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-60%, 30%);
    margin-top: 0rem;
}