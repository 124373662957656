.quality-section-form {
    box-shadow: var(--shadow-2);
    height: auto;
    width: 60rem !important;
    /* width: 100% !important; */
    border-radius: var(--radius-2);
    padding: 2rem;
    /* border: var(--border-dashed); */
}

.quality-section {
    background-color: var(--clr-cream);
    min-height: 30rem;
}

.overlay {
    width: 100%;
    height: 100%;
    background-color: var(--clr-dark);
    opacity: .6;
    position: absolute;
}

.quality-section-top {
    height: auto;
    width: 100%;
}

.quality-section-top-image {
    width: 100%;
    height: 60vh;
    background-image: url('../../../assets/images/chocolat/bg-blue-texture.png');
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    /* border-radius: 0 0 20% 20%; */
    box-shadow: var(--shadow-x);
    /* filter: brightness(0.4); */
    background-attachment: fixed;
    background-position: center;
}

.quality-section-top-cards {
    margin: -18rem 0 0 0;
    z-index: 9;
    position: relative;
}

.quality-section-top-cards-item {
    background-color: var(--clr-light);
    min-width: 290px !important;
    max-width: 330px !important;
    min-height: 26rem !important;
    box-shadow: var(--shadow-x);
    border-radius: var(--radius);
    margin: 2rem 1.3rem;
    padding: 7px 0;
}

.cards-item-text p,
.cards-item-btn {
    transition: var(--transition);
}


.cards-item-btn {
    width: 7rem;
    height: 2.4rem;
    color: var(--clr-dark) !important;
    font-size: 18px !important;
    /* padding: 5px 10px !important; */
    border-radius: var(--radius);
    border: 1px solid var(--clr-light-dark) !important;
}

.cards-item-text p {
    color: var(--clr-blue);
    font-size: 19px;
}

.cards-item-tel {
    font-size: 1.2rem !important;
    letter-spacing: 1px;
}

.cards-item-btn:hover,
.cards-item-tel:hover {
    color: var(--clr-yellow) !important;
}


.cards-item-icon {
    color: var(--clr-yellow);
    font-size: 3.2rem;
}

.cards-item-social-icon {
    font-size: 32px;
    color: var(--clr-dark);
    margin: 0 5px 1px 5px;
    transition: var(--transition);
}

.cards-item-social-image {
    width: 40px;
    height: 100%;
    margin: 0 5px;
    transition: var(--transition);
}

.cards-item-social-icon:hover,
.cards-item-social-image:hover {
    color: var(--clr-yellow);
    transform: scale(1.1);
}

.cards-item-social-size-fb:hover {
    border: 2px solid var(--clr-yellow);
}

.cards-item-social-size-fb {
    border: 2px solid var(--clr-dark);
    border-radius: 8px;
}

@media screen and (max-width:1460px) {
    .quality-section-top-image {
        background-attachment: inherit;
        background-position: inherit;
    }
}

@media screen and (max-width:992px) {
    .quality-section-form {
        width: 100% !important
    }

    .quality-section-top-image {
        background-attachment: inherit;
        background-position: inherit;
    }

    .quality-section-top-image {
        height: 50vh;
    }
}

.login-icon {
    height: 100%;
    width: 40px;
}

@media screen and (max-width:992px) {
    /* .quality-section-top-cards-item {
        width: 200px !important;
        height: 160px !important;
    } */

    /* .cards-item-text p {
        color: var(--clr-yellow);
        font-size: 16px;
    } */

    .cards-item-tel {
        font-size: 1rem !important;
    }

    .cards-item-social-icon {
        font-size: 28px;
    }

    .cards-item-icon {
        font-size: 2.2rem;
    }

    .cards-item-btn {
        width: 6rem;
        height: 2.2rem;
        font-size: 15px !important;
    }
}

@media screen and (max-width:578px) {
    .quality-section-top-image {
        height: 35vh;
    }
}



.form-check-content {
    font-size: 12px !important;
    color: var(--clr-blue);
    transition: var(--transition) !important;
}

.form-check-content:hover {
    color: var(--clr-light-blue);
}

input:focus {
    border: 2px solid var(--clr-blue) !important;
    outline: none !important;
    box-shadow: none !important;
    /* box-shadow: 0 0 0 0.25rem #e7695d2a !important; */
}


.quality-section-title {
    font-size: 3.2rem !important;
    margin: -1rem 0 0 0;
}

.quality-section-container {
    margin: 0 0 20rem 0;
}


.quality-section-text {
    color: var(--clr-yellow);
    font-size: 1.5rem;
}

.quality-section-logo img {
    height: 100%;
    width: 45px;
}

.quality-section-logo {
    height: 100%;
    width: 68px;
    border: 2px solid var(--clr-light-dark);
    padding: 10px;
    border-radius: 50%;
}


.quality-section-message {
    font-family: var(--font-family-lato-reg) !important;
    opacity: 0.99 !important;
    letter-spacing: .02rem;
}

.quality-section-message:focus {
    border: 2px solid var(--clr-blue) !important;
    box-shadow: none !important;
}

.quality-section-info {
    color: var(--clr-dark) !important;
    font-size: 16px !important;
    letter-spacing: .6px;
}