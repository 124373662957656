.goto-boutique {
  color: var(--clr-blue) !important;
  font-size: 14px !important;
}

.goto-boutique:hover {
  color: var(--clr-light-blue) !important;
}

.account-notif {
  opacity: .8;
}

.order-message-icon {
  margin: -11px 0 0 0;
  font-size: 16px;
  color: var(--clr-blue) !important;
}

.order-content {
  /* overflow: scroll !important; */
  max-height: 43rem !important;
}

.order-message {
  height: 6px;
}


.details-view {
  position: relative;
  margin: 0.5rem 0 0.5rem 0;
  width: 50px;
  height: 30px;
  border-radius: 15px;
  box-shadow: var(--shadow-5) !important;
  background-color: var(--clr-light);
}

.order-details-img {
  height: 80px;
  width: 100%;
  border-radius: var(--radius);
  object-fit: cover;
  overflow: hidden;
  transform: scale(1.1);
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

th,
td {
  white-space: nowrap;
}

.pay-loader {
  margin: 0 0px 0 3px;
  transform: scale(0.8);
  top: 6px;
  position: relative;
}

/* ==================== */


/* All badges with class starting with "bm-" */
/* [class^="bm-"] {
    display: inline-block;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    color: #f5f5f5; 
    text-align: center;
  } */