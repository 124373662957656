.account-adresses{
    overflow: hidden !important;
}

.account-adresse-icon {
    color: var(--clr-blue);
    cursor: pointer;
    /* font-size: 34px;
    margin: -15px 0 0 0; */
    font-size: 36px;
    margin: -2px 0 0 6px;
    transition: var(--transition);
    /* display: none; */
}

.account-adresse-icon:hover {
    color: var(--clr-blue);
    transform: rotate(90deg);
}

.account-adresse-edit-content {
    border: 2px dashed var(--clr-blue);
    border-radius: 50%;
    height: 38px;
    width: 38px;
    display: inline-block;
    margin: -6px 2px 3px 7px;
    position: absolute;
    transition: 1.2s;
}

.account-adresse-size-edit {
    color: var(--clr-blue);
    cursor: pointer;
    font-size: 28px;
    margin: 0px 1px 0 2px;
    transition: 1.4s;
}

.account-adresse-edit-content:hover {
    border: 2px dashed var(--clr-blue);
    /* transform: rotate(360deg); */
    transform: scale(1.1);
}

.account-adresse-size-edit:hover {
    color: var(--clr-blue) !important;
}


@media screen and (max-width:1200px) {
    .text-left {
        text-align: left !important;
    }
}

@media screen and (max-width:1200px) {
    .livraison-icon {
        margin: 0 0 0 2.74rem;
    }

    .edit-livraison-icon {
        margin: -6px 2px 3px 2.84rem;
    }
}

.adress-details-text {
    color: var(--clr-dark);
    font-family: var(--font-family-lato-reg);
    font-weight: 600;
    font-size: 18px;
}

.adress-details-value {
    font-family: var(--font-family-lato-reg);
    font-weight: 600;
}

.adress-details-icon {
    color: var(--clr-blue);
}


@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(45deg);
    }

    100% {
        transform: rotate(0deg);
    }
}


.nolocation-img{
    width: 20%;
}

