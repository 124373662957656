.about-us {
    margin: 3rem 12rem !important;
    min-height: 14rem;
}

.tag-hr {
    background-color: var(--clr-yellow);
    height: 3px;
    opacity: 1;
    width: 100%;
}

.tag-hr::before,
.tag-hr::after {
    display: none !important;
}