.moment-card-title {
    position: relative;
}

/* 
.moment-card-title ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 10px;
    display: block;
    background-color: var(--clr-blue) !important;
    opacity: .1;
    width: 90%;
    height: 2px;
    transition: var(--transition);
    padding: 0 10px !important;
} */

.moment-card-title ::before {
    content: "";
    position: absolute;
    bottom: 0;
    /* left: .5rem; */
    display: block;
    background-color: var(--clr-yellow) !important;
    width: 20%;
    height: 2px;
    transition: var(--transition);
    z-index: 1;
}

.moment-card-image,
.moment-card-image img {
    transition: var(--transition) !important;
}

.moment-card-image:hover img {
    transform: scale(1.1);
    box-shadow: var(--shadow-4);
}

.moment-card-image:hover {
    box-shadow: var(--shadow-1);
}